import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Chat from "../assets/images/icon/chat.inline.svg"
import Document from "../assets/images/icon/document.inline.svg"
import Handshake from "../assets/images/icon/handshake.inline.svg"
import Accordion from "../components/accordion"
import BackgroundImageWrapper from "../components/backgroundImage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Testimonials from "../components/testimonials"
import Video from "../components/video"

const Arbeidsconflict = () => {
  const [error, setError] = useState(false)

  const [user, setUser] = useState({
    name: "",
    email: "",
    contactType: "",
    phoneNumber: "",
    contactTime: "",
  })

  const onChangeHandler = event => {
    setUser(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const onSubmit = event => {
    event.preventDefault()

    if (!user.name || !user.email) {
      setError(true)
    }
  }

  return (
    <Layout>
      <Seo title="Mediation-bij-een-arbeidsconflict" />
      <BackgroundImageWrapper hero="hero3" position="center" height="461px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-150px flex justify-center flex-col text-center relative">
          <h1 className="text-4xl font-bold text-white mb-10px">
            Mediation bij een arbeidsconflict
          </h1>
          <p className="text-lg font-normal text-white">
            Zo snel mogelijk een weg vinden om verder te gaan
          </p>
          <div className="flex mt-15px justify-center">
            <Link
              to="/contact"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Intakegesprek aanvragen
            </Link>
          </div>
        </div>
      </BackgroundImageWrapper>

      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Waarom mediation bij een arbeidsconflict?
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
            Arbeidsmediation kan worden ingezet voor alle conflicten op het
            werk. U kunt denken aan een geschil tussen werknemer en
            leidinggevende, twee collega’s die moeten samenwerken, onrust op een
            afdeling, maar ook aan twee directeuren die niet meer goed door een
            deur kunnen.
            <br />
            <br />
            Bij (sluimerende) conflicten op de werkvloer zijn vaak meerdere
            mensen betrokken. Het raakt niet alleen de twee die ogenschijnlijk
            mot hebben met elkaar. Ook de rest van de afdeling wordt erdoor
            beïnvloed. De productiviteit neemt af. Het kan leiden tot ziekte.
            Daarom is er aandacht voor de patronen die zijn ontstaan en ieders
            aandeel daarin. Wat kan gedaan worden om deze patronen te
            doorbreken?
          </p>

          <Video
            className="h-393px max-w-700px"
            videoSrcURL="https://www.youtube.com/embed/0-d-xo5lp4g"
            videoTitle="Arbeidsmediation bij Eerstmediation.nl"
          />
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Arbeidsmediation traject
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary mb-35px">
            Na een intake gesprek met beide partijen start de arbeidsmediation.
            Een arbeidsmediation kent drie fasen. Doorgaans zijn 2 tot 5
            gezamenlijke gesprekken nodig voor het gehele traject.
          </p>
          <div className="flex flex-col gap-y-35px">
            <div className="flex items-center gap-x-5">
              <div className="w-130px flex justify-center">
                <div className="w-100px h-96px">
                  <Handshake />
                </div>
              </div>
              <div className="w-570px">
                <h3 className="text-2xl text-primary mb-10px">
                  Aparte intakegesprekken
                </h3>
                <p className="text-primary text-lg">
                  In het vertrouwelijke intakegesprek kunnen partijen hun
                  verhaal kwijt. De mediator krijgt een beeld hoe partijen in
                  het conflict staan, waar de knelpunten zitten en waar de
                  mogelijke oplossingen liggen. Bovendien hebben partijen de
                  gelegenheid om kennis te maken met de mediator en te bekijken
                  of zij daar voldoende vertrouwen in hebben om de mediation te
                  starten.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-x-5">
              <div className="w-130px flex justify-center">
                <div className="w-100px h-100px">
                  <Chat />
                </div>
              </div>
              <div className="w-570px">
                <h3 className="text-2xl text-primary mb-10px">
                  Gezamenlijke gesprekken
                </h3>
                <p className="text-primary text-lg">
                  In de gezamenlijke gesprekken vertellen partijen elkaar waar
                  ze tegenaan lopen en wat ze nodig hebben om verder te kunnen.
                  Zonder in welles-nietes te vervallen wordt onderzocht of het
                  haalbaar is het vertrouwen te herstellen. Partijen maken
                  afspraken om herhaling te voorkomen. Als verdere samenwerking
                  niet meer haalbaar is wordt onderzocht of er andere interne
                  mogelijkheden zijn waarbij partijen niet meer samen hoeven te
                  werken. Als blijkt dat deze mogelijkheden er niet zijn dan
                  wordt onderzocht of partijen bereid zijn afscheid te nemen van
                  elkaar op een wijze waar beiden mee verder kunnen. In de
                  mediation wordt onderhandeld over de voorwaarden.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-x-5">
              <div className="w-130px flex justify-center">
                <div className="h-100px w-75px">
                  <Document />
                </div>
              </div>
              <div className="w-570px">
                <h3 className="text-2xl text-primary mb-10px">
                  Vastleggen afspraken
                </h3>
                <p className="text-primary text-lg">
                  Als de oplossingsrichting helder is en partijen het eens zijn
                  over de invulling dan legt de mediator de afspraken vast.
                  Afhankelijk van de situatie kan dit een eindverslag zijn dat
                  door partijen wordt goedgekeurd of een
                  (beëindigings)overeenkomst die door partijen wordt getekend.
                  De inhoud van de mediation blijft, ook na afloop,
                  vertrouwelijk. Het eindverslag of de overeenkomst met de
                  concrete afspraken vormt hier een uitzondering op. Dit mag
                  worden toegevoegd aan het personeelsdossier.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Veelgestelde vragen
          </h2>
          <div className="flex flex-col gap-y-2">
            <Accordion
              title="Is arbeidsmediation verplicht of vrijwillig?"
              text="Mediation is formeel vrijwillig maar voelt vaak niet zo. Arbeidsrechtelijk bekeken moeten partijen vaak wel meewerken om te voorkomen dat een weigering tegen hen gebruik wordt. Toch is vrijwilligheid een kernwaarde van mediation. Bij Eerstmediation.nl wordt de vrijwilligheid teruggebracht: als u bij de mediator aangeeft dat u er geen heil in ziet dan zal de mediator de mediation op een neutrale manier beëindigen zonder aan te geven wie het initiatief daarvoor genomen heeft. Zodat het beëindigen van de mediation nooit tegen u gebruikt kan worden."
            />

            <Accordion
              title="Waarom is mediation vertrouwelijk?"
              text="Als er spanningen ontstaan in de arbeidsverhouding dan gaan mensen nadenken voordat ze iets zeggen. Ze zijn bang iets te zeggen dat later tegen hen gebruikt wordt. Dat is begrijpelijk. Het maakt echter ook dat er al snel geen open gesprek meer mogelijk is. En dan is het moeilijk om er nog samen uit te komen. Door de vertrouwelijkheid van de mediation mag niets van wat u zegt later tegen u gebruikt worden. Dat maakt dat u echt kunt zeggen wat u wilt en welke oplossing u wenst."
            />

            <Accordion
              title="Waar moet ik op letten bij het zoeken van een arbeidsmediator?"
              list={[
                "Is de mediator opgenomen in het MfN Register? Dat register waarborgt de kwaliteit en ervaring van mediators. Zowel de rechtbank als de Raad voor Rechtsbijstand werken alleen met MfN Registermediators.",
                "Is de mediator gespecialiseerd in arbeidsconflicten? Kijk hiervoor op de website van de mediator.",
                "Wat is de achtergrond van de mediator? Mediators hebben in het verleden allemaal iets anders gedaan. Zoek een mediator die past bij uw wensen. Achterhaal de achtergrond van de mediator via zijn website of linkedinpagina. Als u geen informatie kunt vinden is dit geen goed teken.",
              ]}
            />

            <Accordion
              title="Is de mediator wel neutraal als de werkgever hem betaalt?"
              text="Voor een mediator is neutraliteit het hoogste goed. Als beide partijen een mediator niet als onpartijdig ervaren is de mediator uitgedaan. Bij een professionele mediator maakt het niet uit wie hem uitzoekt of  wie hem betaalt. In het intakegesprek kunt u bekijken of u vertrouwen heeft in de mediator. Als dat niet het geval is, dan hoeft u niet met deze mediator in zee te gaan."
            />
          </div>
          <p className="text-lg text-primary my-30px">
            Andere vraag? Neem gerust{" "}
            <Link to="/contact" className="text-link cursor-pointer">
              contact
            </Link>{" "}
            op.
          </p>
        </div>
      </section>

      <section className="py-75px px-5 bg-light-green text-center ">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal mb-30px text-white">
            Intakegesprek aanvragen
          </h2>
          <div className="flex gap-x-2 mt-15px justify-center">
            <Link
              to="/contact"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Contact Opnemen
            </Link>
          </div>
        </div>
      </section>
      
      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary mb-30px">
            Reacties van klanten
          </h2>
          <Testimonials />
          <Link
            to="/recensies"
            className="inline-block py-10px px-14px text-lg underline text-link hover:text-primary"
          >
            Meer reacties
          </Link>
        </div>
      </section>

      <section className="py-75px text-center">
        <div className="max-w-700px mx-auto">
          <StaticImage
            src="../assets/images/eerstmediation-thumbnail-big.png"
            placeholder="blurred"
            className="h-98px w-98px mb-5"
            alt="eerstmediation thumbnail big"
          />
          <h2 className="text-27px mb-5 leading-1.2 font-normal text-primary">
            Over het kantoor
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary ">
            Eerstmediation.nl is een Mediation & Advocatenkantoor dat is
            gespecialiseerd in mediation bij echtscheidingen en
            arbeidsconflicten in het Land van Cuijk en de regio Nijmegen.
          </p>
          <Link
            to="/over-het-kantoor"
            className="inline-block py-10px px-14px text-lg underline text-link hover:text-primary"
          >
            Meer over het kantoor
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default Arbeidsconflict
